import { instance } from 'api/internaltools';

export const getMerchantTransactions = (id, transaction_limit, page) => {
  return instance.get(`/merchant/${id}/transactions?limit=${transaction_limit}&page=${page}`);
};

export const getTransactions = (
  transaction_limit,
  page,
  statuses = '',
  searchKey = '',
  is_exclude_test_transactions = false,
  is_fetch_stuck_transactions = false
) => {
  let query = `/transactions?limit=${transaction_limit}&page=${page}`;

  if (statuses) {
    query += `&statuses=${statuses}`;
  }

  if (searchKey) {
    query += `&search=${searchKey}`;
  }

  if (is_fetch_stuck_transactions) {
    query += `&fetch_stuck_transactions=${is_fetch_stuck_transactions}`;
  }

  if (is_exclude_test_transactions) {
    query += `&is_exclude_test_transactions=${is_exclude_test_transactions}`;
  }

  return instance.get(query);
};

export const getPayoutSessionStepLevelProgress = (id) => {
  return instance.get(`/transaction/${id}/progress`);
};

export const retriggerPayoutSessionStepEvent = (payload) => {
  return instance.post('/transaction/event/retrigger', payload);
};

export const getTransactionsForApproval = () => {
  return instance.get('/transactions/approval');
};

export const getTransactionDetails = (id) => {
  return instance.get(`/transaction-v2/${id}/details`);
};

export const approveTransaction = (payload) => {
  return instance.post('/transaction-v2/approve', payload);
};

export const getUserTransactionLimit = (id) => {
  return instance.get(`/users/${id}/transaction-approval-limit`);
};

export const setUserTransactionLimit = (id, payload) => {
  return instance.post(`/users/${id}/transaction-approval-limit`, payload);
};

export const updateUserTransactionLimit = (id, payload) => {
  return instance.patch(`/users/${id}/transaction-approval-limit`, payload);
};

export const getTransactionsRequests = (status, transactionType) => {
  return instance.get(
    `/transactions/requests?policy_evaluation_statuses=${status}&transaction_type=${transactionType}`
  );
};

export const getDetailedTransactionRequest = (id) => {
  return instance.get(`/transactions/requests/${id}`);
};

export const approveTransactionRequest = (id, payload) => {
  return instance.post(`/transactions/${id}/approve`, payload);
};

export const declineTransactionRequest = (id, payload) => {
  return instance.post(`/transactions/${id}/decline`, payload);
};
