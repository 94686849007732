import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updatePayoutSessionStatusReferenceId } from 'api/internaltools';
import { TRANSACTION_STATUS } from 'modules/merchantinfo/constants/constants';
import { showError, showToast } from 'utils/notifications';

export default function ModifyMerchantTransactionStatusModal({
  isOpen,
  onClose,
  transaction,
  onUpdateTransaction,
}) {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [bankReferenceId, setBankReferenceId] = useState('');
  const [estimatedTime, setEstimatedTime] = useState('');
  const [isModifyTransactionLoading, setIsModifyTransactionLoading] = useState(false);
  const [isModifyBtnDisabled, setIsModiyBtnDisabled] = useState(true);

  const isSelectStatusDisabled =
    transaction?.status !== TRANSACTION_STATUS.INITIATED && transaction?.status !== TRANSACTION_STATUS.DELAYED;

  const isValidDate = (dateString) => {
    if (!dateString) return true;

    const regex = /^\d{4}-\d{2}-\d{2}$/;

    return regex.test(dateString);
  };

  const isDateError = !isValidDate(estimatedTime);

  useEffect(() => {
    if (transaction?.status === TRANSACTION_STATUS.INITIATED || transaction?.status === TRANSACTION_STATUS.DELAYED) {
      if (isDateError) {
        setIsModiyBtnDisabled(true);

        return;
      }

      if (selectedStatus === TRANSACTION_STATUS.SUCCEEDED) {
        setEstimatedTime('');
        setIsModiyBtnDisabled(false);
      } else if (selectedStatus === TRANSACTION_STATUS.DELAYED && estimatedTime) {
        setIsModiyBtnDisabled(false);
      } else if (!selectedStatus && bankReferenceId) {
        setEstimatedTime('');
        setIsModiyBtnDisabled(false);
      } else if (selectedStatus === TRANSACTION_STATUS.FAILED) {
        setEstimatedTime('');
        setIsModiyBtnDisabled(false);
      }
      else {
        setIsModiyBtnDisabled(true);
      }
    } else if (bankReferenceId) {
      setIsModiyBtnDisabled(false);
    } else {
      setIsModiyBtnDisabled(true);
    }
  }, [selectedStatus, bankReferenceId, estimatedTime, transaction?.status]);

  const toast = useToast();

  const handleTransactionModify = async () => {
    setIsModifyTransactionLoading(true);

    try {
      const payload = {};

      if (selectedStatus) {
        payload.status = selectedStatus;
      }
      if (bankReferenceId) {
        payload.bank_reference_id = bankReferenceId;
      }
      if (estimatedTime) {
        payload.new_estimated_time = estimatedTime;
      }

      if (transaction?.status === TRANSACTION_STATUS.DELAYED && estimatedTime) {
        payload.status = TRANSACTION_STATUS.DELAYED;
      }

      if (!Object.keys(payload).length) {
        onClose();

        return;
      }

      payload.payout_session_id = transaction?.id;
      payload.parent_id = transaction?.parent_id;

      const response = await updatePayoutSessionStatusReferenceId(payload);

      onUpdateTransaction(response?.data?.data);

      const toastDescription = `Transaction status will be modified to ${selectedStatus} shortly. Please DO NOT change it again!`;

      showToast(toast, 'Transaction modified successfully.', toastDescription);
    } catch (error) {
      if (error?.response?.data?.error?.code === 'SETTLEMENT_RECON_EXECUTED') {
        showError(
          toast,
          'Transaction cannot be marked failed because its recon has already been executed. Please do DEBIT REVERSAL for the same.',
          ''
        );
      } else {
        showError(toast, 'Error modifying transaction.', error);
      }
    }

    setIsModifyTransactionLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modify Transaction Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} bg='gray.100' borderRadius='8px' p={3}>
            <Text mb={2} fontSize='sm'>
              Transaction ID: {transaction?.id}
            </Text>
            <Text fontWeight='bold' mb={2}>
              {transaction?.title} | Amt: {transaction?.amount} {transaction?.currency_code}
            </Text>
            <Text>
              Status:{' '}
              <Tag variant='solid' colorScheme='gray'>
                {transaction?.status}
              </Tag>
            </Text>
          </Box>
          <Select
            placeholder='Select Status'
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            isDisabled={isSelectStatusDisabled}
          >
            {(transaction?.status === TRANSACTION_STATUS.INITIATED ||
              transaction?.status === TRANSACTION_STATUS.DELAYED) && (
              <>
                <option value={TRANSACTION_STATUS.SUCCEEDED}>Succeeded</option>
                <option value={TRANSACTION_STATUS.DELAYED}>Delayed</option>
                <option value={TRANSACTION_STATUS.FAILED}>Failed</option>
              </>
            )}
          </Select>

          {selectedStatus === TRANSACTION_STATUS.DELAYED && (
            <FormControl mt={4} isInvalid={isDateError}>
              <FormLabel>Estimated Completion Time (YYYY-MM-DD)</FormLabel>
              <Input
                placeholder='Estimated Completion Time'
                value={estimatedTime}
                onChange={(e) => setEstimatedTime(e.target.value)}
              />
              {isDateError && <FormErrorMessage>Date should be in format YYYY-MM-DD.</FormErrorMessage>}
            </FormControl>
          )}
          <Box mt={4}>
            <FormLabel htmlFor='bankReferenceId'>Bank Reference ID</FormLabel>
            <Input
              id='bankReferenceId'
              placeholder='Bank Reference ID'
              value={bankReferenceId}
              onChange={(e) => setBankReferenceId(e.target.value)}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleTransactionModify}
            isDisabled={isModifyBtnDisabled}
            isLoading={isModifyTransactionLoading}
          >
            Modify
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
