import React, { useEffect, useState } from 'react';
import { Flex, Table, Tbody, Td,Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { getCounterPartyRiskDetails } from 'api/internaltools';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
export default function PartnerVisibility() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [counterPartyRiskDetails, setCounterPartyRiskDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCounterPartyRiskDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getCounterPartyRiskDetails();

      setCounterPartyRiskDetails(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCounterPartyRiskDetails();
  }, []);

  return (
    <Flex direction='column' pt='30px'>
      <Text fontSize='xl' color={textColor} fontWeight='bold'>
        Counterparty Details
      </Text>
      <LoadingErrorWrapper isLoading={isLoading} errorTitle='Error fetching counterparty details.' error={error}>
        <Table mt={4}>
          <Thead>
            <Tr color='gray.400'>
              <Th>Partner Code</Th>
              <Th>Available Balance</Th>
              <Th>Hold Balance</Th>
              <Th>Total Balance</Th>
              <Th>Counterparty Risk Threshold</Th>
              <Th>Counterparty Risk Buffer</Th>
              <Th>Last Synced At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {counterPartyRiskDetails?.map((counterparty, idx) => (
              <Tr key={idx}>
                <Td fontWeight="bold">{counterparty?.partner_code}</Td>
                <Td>{counterparty?.readable_available_balance}</Td>
                <Td>{counterparty?.readable_hold_balance}</Td>
                <Td fontWeight="bold">{counterparty?.readable_total_balance}</Td>
                <Td fontWeight="bold">{counterparty?.readable_counterparty_risk_threshold}</Td>
                <Td fontWeight="bold">{counterparty?.readable_counterparty_risk_buffer}</Td>
                <Td>{new Date(counterparty?.last_synced_at).toLocaleString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </LoadingErrorWrapper>
    </Flex>
  );
}
