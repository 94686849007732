import { AiOutlineTransaction } from 'react-icons/ai';
import { BiHomeAlt2 } from 'react-icons/bi';
import { FiSend, FiUpload } from 'react-icons/fi';
import { GoRocket } from 'react-icons/go';
import { MdOutlinePolicy, MdOutlineVisibility } from 'react-icons/md';
import { PiVault } from 'react-icons/pi';
import { RiGroupLine, RiUserSearchLine } from 'react-icons/ri';
import { VscArrowSwap, VscTools } from 'react-icons/vsc';
import { PermissionsV2 } from 'constants/permissions_v2';
import BankingOnboarding from 'modules/onboarding/BankingOnboarding';
import TreasuryOnboarding from 'modules/onboarding/TreasuryOnboarding';
import CreatePolicy from 'modules/policies/CreatePolicy';
import BankingPostOnboarding from 'modules/postonboarding/BankingPostOnboarding';
import Accounts from 'pages/accounts';
import ApproveTransactionsV2 from 'pages/approve-transactions-v2';
import Corridors from 'pages/corridors';
import Home from 'pages/home';
import InternalUsers from 'pages/internalusers';
import MerchantInfo from 'pages/merchantinfo';
import Merchants from 'pages/merchants';
import Onboarding from 'pages/onboarding';
import PartnerVisibility from 'pages/partner-visibility';
import Policies from 'pages/policies';
import PostOnboarding from 'pages/postonboarding';
import TechSupportRequest from 'pages/techsupport';
import TransactionsV2 from 'pages/transactions_v2';

export var opsDashboardRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: <BiHomeAlt2 color='inherit' />,
    component: Home,
    layout: '/dashboard',
    base_permission: '',
  },
  {
    path: '/merchant/:merchantId',
    name: 'MerchantInfo',
    icon: <RiUserSearchLine color='inherit' />,
    component: MerchantInfo,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  {
    path: '/merchants',
    name: 'Merchants',
    icon: <RiUserSearchLine color='inherit' />,
    component: Merchants,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  {
    path: '/corridors',
    name: 'Corridors',
    icon: <VscArrowSwap color='inherit' />,
    component: Corridors,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_CORRIDORS,
  },
  {
    path: '/kyb-submission/banking/entity/:entityId',
    name: 'Banking KYB Submission',
    icon: <FiUpload color='inherit' />,
    component: BankingOnboarding,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_KYB,
  },
  {
    path: '/kyb-submission/treasury/entity/:entityId',
    name: 'Treasury KYB Submission',
    icon: <FiUpload color='inherit' />,
    component: TreasuryOnboarding,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_KYB,
  },
  {
    path: '/account-management',
    name: 'Add Account',
    icon: <PiVault color='inherit' />,
    component: Accounts,
    layout: '/dashboard',
    base_permission: PermissionsV2.CREATE_ACCOUNTS,
  },
  {
    path: '/transactions',
    name: 'Transactions',
    icon: <AiOutlineTransaction color='inherit' />,
    component: TransactionsV2,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_BANKING_PAYOUT,
  },
  {
    path: '/payments',
    name: 'Payments',
    icon: <FiSend color='inherit' />,
    component: ApproveTransactionsV2,
    layout: '/dashboard',
    base_permission: PermissionsV2.APPROVE_BANKING_PAYOUT,
  },
  {
    path: '/partner-visibility',
    name: 'Partner Visibility',
    icon: <MdOutlineVisibility color='inherit' />,
    component: PartnerVisibility,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_ACCOUNTS,
  },
  {
    path: '/kyb-submission',
    name: 'Submit Customer KYB',
    icon: <FiUpload color='inherit' />,
    component: Onboarding,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_KYB,
  },
  {
    path: '/post-onboarding/banking/entity/:entityId',
    name: 'Banking Post Onboarding',
    icon: <GoRocket color='inherit' />,
    component: BankingPostOnboarding,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_POST_ONBOARDING,
  },
  {
    path: '/post-onboarding',
    name: 'Post Onboard Entity',
    icon: <GoRocket color='inherit' />,
    component: PostOnboarding,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_POST_ONBOARDING,
  },
  {
    path: '/tech-support-request',
    name: 'Request Tech Support',
    icon: <VscTools color='inherit' />,
    component: TechSupportRequest,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_TECH_SUPPORT,
  },
  {
    path: '/users',
    name: 'Users',
    icon: <RiGroupLine color='inherit' />,
    component: InternalUsers,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_INTERNAL_USERS,
  },
  {
    path: '/policies/create',
    name: 'Create Policies',
    icon: <MdOutlinePolicy color='inherit' />,
    component: CreatePolicy,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_PAYMENT_POLICIES,
  },
  {
    path: '/policies',
    name: 'Policies',
    icon: <MdOutlinePolicy color='inherit' />,
    component: Policies,
    layout: '/dashboard',
    base_permission: PermissionsV2.MANAGE_PAYMENT_POLICIES,
  },
];

export var lumosDashboardRoutes = [
  {
    path: '/merchants',
    name: 'Merchants',
    icon: <RiUserSearchLine color='inherit' />,
    component: Merchants,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
  {
    path: '/merchant/:merchantId',
    name: 'MerchantInfo',
    icon: <RiUserSearchLine color='inherit' />,
    component: MerchantInfo,
    layout: '/dashboard',
    base_permission: PermissionsV2.VIEW_MERCHANTS,
  },
];
