import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PermissionsV2 } from 'constants/permissions_v2';
import { TRANSACTIONS_TITLE } from 'modules/merchantinfo/constants/constants';
import MerchantAccountsDetails from 'modules/merchantinfo/MerchantAccountsDetails';
import MerchantAndEntityDetails from 'modules/merchantinfo/MerchantAndEntityDetails';
import MerchantBankingDetails from 'modules/merchantinfo/MerchantBankingDetails';
import MerchantCorridorsManagement from 'modules/merchantinfo/MerchantCorridorsManagement';
import MerchantEntitiesKYBDetails from 'modules/merchantinfo/MerchantEntitiesKYBDetails';
import MerchantInvestmentDetails from 'modules/merchantinfo/MerchantInvestmentDetails';
import MerchantTransactionsList from 'modules/merchantinfo/MerchantTransactionsList';
import { isOpsDashboard } from 'utils/utils';
import PermissionWrapper from 'components/hoc/PermissionWrapper';

export default function MerchantInfo() {
  let params = useParams();

  if (!isOpsDashboard()) {
    return (
      <Tabs isFitted variant='enclosed-colored' isLazy>
        <TabList mt={6}>
          <PermissionWrapper permission={PermissionsV2.VIEW_KYB_DETAILS}>
            <Tab>KYB Details</Tab>
          </PermissionWrapper>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PermissionWrapper permission={PermissionsV2.VIEW_KYB_DETAILS}>
              <MerchantEntitiesKYBDetails merchantId={params?.merchantId} />
            </PermissionWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <Tabs isFitted variant='enclosed-colored' isLazy>
      <TabList mt={6} mb={3}>
        <Tab>Merchant Information</Tab>
        <PermissionWrapper permission={PermissionsV2.MANAGE_MERCHANT_CORRIDORS}>
          <Tab>Corridor Management</Tab>
        </PermissionWrapper>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Grid templateColumns={{ sm: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={6}>
            <MerchantAndEntityDetails merchantId={params?.merchantId} />
            <PermissionWrapper permission={PermissionsV2.VIEW_ACCOUNTS}>
              <MerchantBankingDetails merchantId={params?.merchantId} />
            </PermissionWrapper>
            <PermissionWrapper permission={PermissionsV2.VIEW_ACCOUNTS}>
              <MerchantAccountsDetails merchantId={params?.merchantId} />
            </PermissionWrapper>
          </Grid>
          <PermissionWrapper permission={PermissionsV2.VIEW_MERCHANT_INVESTMENTS}>
            <MerchantInvestmentDetails merchantId={params?.merchantId} />
          </PermissionWrapper>
          <PermissionWrapper permission={PermissionsV2.VIEW_MERCHANT_TRANSACTIONS}>
            <MerchantTransactionsList merchantId={params?.merchantId} title={TRANSACTIONS_TITLE} />
          </PermissionWrapper>
        </TabPanel>
        <TabPanel>
          <PermissionWrapper permission={PermissionsV2.MANAGE_MERCHANT_CORRIDORS}>
            <MerchantCorridorsManagement merchantId={params?.merchantId} />
          </PermissionWrapper>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
