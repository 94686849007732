import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Select from 'react-select';
import { Box, Button, Heading, Text, useToast } from '@chakra-ui/react';
import { createNewAccountOnPartner, getEntityInitiatedAccounts, getSupportedCurrencies } from 'api/internaltools';
import { PARTNER_TYPE_FIAT } from 'constants/constants';
import { ACCOUNT_STATUS_INITIALIZED } from 'modules/accounts/constants/constants';
import { PostOnboardingContext } from 'modules/postonboarding/BankingPostOnboarding';
import { showError, showToast } from 'utils/notifications';
import AddAccountButton from 'components/AddAccountButton/AddAccountButton';
import AddAccountWithPayinModal from 'components/AddAccountWithPayinModal/AddAccountWithPayinModal';

export default function AddFiatCryptoAccounts() {
  const {
    merchantId,
    entityId,
    fiatPartner,
    cryptoPartner,
    nextStep,
    fiatPartnerCustomerId,
    fiatPartnerWalletId,
  } = useContext(PostOnboardingContext);
  const toast = useToast();

  const [fiatCurrencyOptions, setFiatCurrencyOptions] = useState([]);
  const [cryptoCurrencyOptions, setCryptoCurrencyOptions] = useState([]);

  const [selectedFiatCurrencies, setSelectedFiatCurrencies] = useState([]);
  const [selectedCryptoCurrencies, setSelectedCryptoCurrencies] = useState([]);
  const [isAedAccountSelected, setIsAedAccountSelected] = useState(false);

  const [isPayinMarkupModalOpen, setIsPayinMarkupModalOpen] = useState(false);
  const [selectedCurrencyForPayinMarkupModal, setSelectedCurrencyForPayinMarkupModal] = useState('');

  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  const handleOpenPayinMarkupModal = (currencyCode) => {
    setSelectedCurrencyForPayinMarkupModal(currencyCode);
    setIsPayinMarkupModalOpen(true);
  };

  const handleAddAccount = async (currencyCode, payinMarkup = null, isCreateWithoutDepositInstructions = false) => {
    const payload = {
      merchant_id: merchantId,
      entity_id: entityId,
      currency_code: currencyCode,
    };

    if (payinMarkup) {
      payload.currency_type = PARTNER_TYPE_FIAT;
      payload.partner_code = fiatPartner;
      payload.customer_id = fiatPartnerCustomerId;
      payload.wallet_id = fiatPartnerWalletId;
      payload.payin_markup = payinMarkup;
    }

    if (isCreateWithoutDepositInstructions) {
      payload.is_create_without_deposit_instructions = isCreateWithoutDepositInstructions;
    }

    try {
      const response = await createNewAccountOnPartner(payload);

      if (
        response?.data?.data?.status === ACCOUNT_STATUS_INITIALIZED ||
        response?.data?.data?.is_deposit_instructions_pending
      ) {
        showToast(toast, 'Pending', `Account ${currencyCode} currently pending activation.`, 'info');
        setIsNextBtnDisabled(true);
      } else {
        showToast(toast, 'Account created', `Account successfully created for ${currencyCode}`, 'success');
      }

      setSelectedFiatCurrencies((prevSelected) => prevSelected.filter((c) => c?.value !== currencyCode));
      setIsAedAccountSelected(false);
      setSelectedCryptoCurrencies((prevSelected) => prevSelected.filter((c) => c?.value !== currencyCode));
    } catch (error) {
      showError(toast, `Error creating account: ${currencyCode}.`, error);
    }
  };

  const fetchSupportedCurrencies = async () => {
    try {
      const response = await getSupportedCurrencies(fiatPartner, cryptoPartner);
      const formattedFiatOptions = response?.data?.data?.fiatPartner.map((currency) => ({
        value: currency?.id,
        label: currency?.value,
      }));
      const formattedCryptoOptions = response?.data?.data?.cryptoPartner.map((currency) => ({
        value: currency?.id,
        label: currency?.value,
      }));

      setFiatCurrencyOptions(formattedFiatOptions);
      setCryptoCurrencyOptions(formattedCryptoOptions);
    } catch (error) {
      showError(toast, 'Error getting supported currencies', error);
    }
  };

  const fetchEntityInitiatedAccounts = async () => {
    try {
      const response = await getEntityInitiatedAccounts(entityId);
      const initiatedAccounts = response?.data?.data;

      if (initiatedAccounts.length) {
        setIsNextBtnDisabled(true);
      }
    } catch (error) {
      showError(toast, 'Error getting entity initiated accounts', error);
    }
  };

  const handleFiatCurrencyChange = (selectedOptions) => {
    setSelectedFiatCurrencies(selectedOptions);
  };

  // const handleAedCurrencyChange = (selectedOptions) => {
  //   setIsAedAccountSelected(selectedOptions?.length > 0);
  // };

  const handleCryptoCurrencyChange = (selectedOptions) => {
    setSelectedCryptoCurrencies(selectedOptions);
  };

  useEffect(() => {
    if (fiatPartner || cryptoPartner) {
      fetchSupportedCurrencies();
      fetchEntityInitiatedAccounts();
    }
  }, [fiatPartner, cryptoPartner]);

  return (
    <>
      <Heading mb={4}>Add Accounts</Heading>
      <Text>Selected Fiat Partner: {fiatPartner ? fiatPartner : 'not selected.'}</Text>
      <Text>Selected Crypto Partner: {cryptoPartner ? cryptoPartner : 'not selected.'}</Text>
      <Box w='400px' mt={4}>
        <Box>
          <Heading size='md' mb={2}>
            Fiat Accounts
          </Heading>
          <Select
            value={selectedFiatCurrencies}
            onChange={handleFiatCurrencyChange}
            options={fiatCurrencyOptions}
            isMulti
            placeholder='Select Fiat currencies'
            isDisabled={!fiatPartner}
          />
        </Box>

        {/* {fiatPartner === PARTNER_CODE_LAYER2 && (
          <Box mt={4}>
            <Heading size='md' mb={2}>
              Add AED Account
            </Heading>
            <Select
              value={isAedAccountSelected ? [{ value: 'AED', label: 'AED' }] : []}
              options={[{ value: 'AED', label: 'AED' }]}
              onChange={handleAedCurrencyChange}
              isMulti
            />
          </Box>
        )} */}

        <Box mt={4}>
          <Heading size='md' mb={2}>
            Crypto Accounts
          </Heading>
          <Select
            value={selectedCryptoCurrencies}
            onChange={handleCryptoCurrencyChange}
            options={cryptoCurrencyOptions}
            isMulti
            placeholder='Select Crypto currencies'
            isDisabled={!cryptoPartner}
          />
        </Box>
      </Box>

      {selectedFiatCurrencies.length || isAedAccountSelected || selectedCryptoCurrencies.length ? (
        <Text mt={6} fontWeight='bold'>
          Selected Currencies:
        </Text>
      ) : null}

      {selectedFiatCurrencies.map((currency) => (
        <AddAccountButton
          key={currency?.value}
          currencyCode={currency?.value}
          onClick={() => handleOpenPayinMarkupModal(currency?.value)}
        />
      ))}

      {isAedAccountSelected && <AddAccountButton key='AED' currencyCode='AED' onClick={handleAddAccount} />}

      {selectedCryptoCurrencies.map((currency) => (
        <AddAccountButton key={currency?.value} currencyCode={currency?.value} onClick={handleAddAccount} />
      ))}

      {isPayinMarkupModalOpen && (
        <AddAccountWithPayinModal
          currencyCode={selectedCurrencyForPayinMarkupModal}
          onClose={() => setIsPayinMarkupModalOpen(false)}
          onClick={handleAddAccount}
        />
      )}

      <Button
        mt={8}
        colorScheme='blue'
        onClick={nextStep}
        rightIcon={<AiOutlineArrowRight />}
        isDisabled={isNextBtnDisabled}
      >
        Next
      </Button>
    </>
  );
}
